/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Core

@import "argon-dashboard/custom/functions";
@import "argon-dashboard/custom/variables";
@import "argon-dashboard/custom/mixins";

// bootstrap (4.6.0) components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

// Argon utilities and components

@import "argon-dashboard/custom/reboot";
@import "argon-dashboard/custom/utilities";
@import "argon-dashboard/custom/components";

// Vendor (Plugins)

@import "argon-dashboard/custom/vendors";

// Docs components

@import "argon-dashboard/docs/variables";
@import "argon-dashboard/docs/nav";
@import "argon-dashboard/docs/clipboard-js";
@import "argon-dashboard/docs/component-examples";
@import "argon-dashboard/docs/prism";
@import "argon-dashboard/docs/content";
@import "argon-dashboard/docs/sidebar";
@import "argon-dashboard/docs/footer";

// React Differences
@import "react/react-differences";

.visually-hidden {
  display: none;
}

p {
  word-wrap: break-word;
}

.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home {
  background-color: white;
  height: 100vh;
  .copy-rights {
    text-align: center;
    font-size: 11px;
    margin-top: 5px;
  }
  .watermark {
    position: absolute;
    max-width: 600px;
    opacity: 0.3;
    right: calc(50vw - 500px);
    top: -20px;
  }
}

.home-contaienr {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 100px;
  height: 100vh;

  span {
    display: flex;
    align-items: flex-end;
    img {
      max-height: 20px;
      margin-right: 3px;
    }
    h6 {
      margin-bottom: 5px;
      color: #707070;
    }
    margin-top: 36px;
  }

  .card {
    max-width: 450px;
    border: none;
    padding: 50px;
    border-radius: 9px;
    background: #ffffff;
    box-shadow: 0px 0px 20px -6px rgba(0, 0, 0, 0.08);
    p {
      text-align: center;
    }

    a {
      color: #525f7f;
      font-weight: 600;
    }

    img {
      height: 100%;
      width: 180px;
      display: block;
      margin: auto;
    }
  }
}

.card-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  .footer-bottom {
    margin-bottom: 40px;
    span {
      display: flex;
      justify-content: center;
      img {
        max-height: 20px;
      }
      h6 {
        margin-bottom: 5px;
        color: #707070;
      }
    }
    .copy-rights {
      text-align: center;
      font-size: 11px;
      margin-top: 5px;
    }
  }
}

.input-container {
  &.sm {
    max-width: 80px;
  }

  label {
    font-size: 12px;
    margin-bottom: 3px;
  }
  .text-input {
    border: solid #9d9d9d30 1px;
    padding: 5px 3px 5px 3px;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    input {
      border: none;
      width: 100%;
      font-size: 14px;
      font-weight: 300;
      flex: 1;
    }
    section {
      width: 50px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.tokenize {
  max-width: 350px;
  width: 100%;
  min-width: 250px;
  border: none;
  padding: 10px 15px;
  border-radius: 9px;
  background: #ffffff;
  box-shadow: 0px 0px 20px -6px rgba(0, 0, 0, 0.08);
  margin-top: 30px;
  margin-bottom: 100px;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #9d9d9d30;
    margin-bottom: 10px;
  }

  .names {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .input-container {
      flex: 1;
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .expiry-container {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
    flex: 1.5;
  }

  button {
    padding: 8px;
    margin: 20px 0 10px 0;
  }

  .card-error {
    color: red;
    margin: 0;
    margin-bottom: -10px;
    font-size: 12px;
    font-weight: 400;
  }
}

.payment-status {
  min-width: 300px;
  i {
    font-size: 80px;
    &.success {
      color: #2dce89;
    }
    &.failed {
      color: #f5365c;
    }
    margin-bottom: 10px;
  }

  section {
    p {
      margin: 0;
    }
  }
}
