//
// Header
//

.header {
  position: relative;

  .spinner-border {
    display: block;
    margin: auto;
  }
}
